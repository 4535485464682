/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image, PriceWrap, Text, Button, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="--center pb--0 pt--20" name={"zlutypruh"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--center pb--30 pt--30" name={"gts53d1e6bo"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--36" content={"<span style=\"color: var(--white);\">Ceník</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--12" name={"pqy6zxzu3qd"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1000}} columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 el--1" anim={"2"} style={{"maxWidth":1000}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--center fs--20" content={"Cenu úklidu můžeme vytvořit na míru s přihlédnutím k vašemu rozpočtu."}>
              </Subtitle>

              <Image alt={""} src={"https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=350x_.png 350w, https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=660x_.png 660w, https://cdn.swbpg.com/t/17432/d51ba375498b4eacb7ee86608cae3b7d_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box pb--08" content={"<span style=\"font-weight: bold;\">Úklid domácností</span>"}>
              </Subtitle>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Pravidelný úklid</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 hod</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">250 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Jednorázový úklid</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">od 15 m2</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">od 500 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Mytí oken</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 m2</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Dle domluvy</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Čištění žaluzií </span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">1x</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">od 30 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Extrakční čištění koberců</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 m2</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Dle domluvy</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Žehlení</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(242, 247, 252); color: rgb(242, 247, 252);\">za 1 hod</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">250 Kč</span><br>"}>
                  </Text>

                  <Text className="text-box" content={"<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Tepování sedačky</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(242, 247, 252); color: rgb(242, 247, 252);\">&nbsp; &nbsp;jedno sezení</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">200 Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(242, 247, 252); color: rgb(242, 247, 252);\">SOS služba 24h/7 (havarie v domácnosti)</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(242, 247, 252); color: rgb(242, 247, 252);\">1 výjezd</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">1500 Kč&nbsp;</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 el--1" anim={"2"} style={{"maxWidth":1000}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box pb--08" content={"<span style=\"font-weight: bold;\">Úklid kanceláří/komerčních prostorů</span>"}>
              </Subtitle>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Pravidelný úklid</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">za 1 hod</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">od &nbsp;200 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Jednorázový úklid</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 hod</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">od 300 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Mytí oken</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 m2</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">dle domluvy</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Mytí výloh</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 m2</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Dle dohody</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Čištění žaluzií </span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">1x</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">od 30Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">Extrakční čištění koberců</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--white);\">za 1 m2</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Dle dohody</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--3" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">Tepování sedaček a židlí</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">jedno sezení</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">200 Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 el--1" anim={"2"} style={{"maxWidth":1000}} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--06 pt--02" name={"9rt83j6ggi4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box" url={"https://uklidnaklik.inrs.cz/rs/"} href={"https://uklidnaklik.inrs.cz/rs/"} style={{"backgroundColor":"var(--color-custom-1)"}} content={"<span style=\"font-weight: bold;\">Rezervace úklidu</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider className="mt--20">
              </Divider>

              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold;\"><br>Kontakt:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-supplementary);\"><span style=\"font-weight: bold;\">+420</span> <span style=\"font-weight: bold;\">734 148 215</span></span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":0}} content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\" style=\"font-weight: bold; color: var(--color-supplementary);\">uklidnaklik@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}